import dynamic from 'next/dynamic'
import { Spinner } from '@stone-payments/jade'

import { withPageConfig } from '~/domains/platform/infra/page-enhancers/with-page-config'

const Feature = dynamic(() => import('identity/auth/login').then(mod => mod), {
  loading: () => <Spinner size="medium" />
})

export default withPageConfig(() => <Feature />, {
  deusExMachina: false,
  navigation: undefined,
  heimdallSetFeatureFlags: false
})
